//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "iconComponent",

    props: {
        icon: String,
        iconTitle: String,
        iconText: String,
        withWrap: Boolean,
        alternative: String
    }
    };
