export const servicesArr = [
    {
        id: "marketing",
        title: "marketingTitle",
        copy: "marketingCopy",
        illustration: require("../assets/img/marketingIllustration.svg"),
        alternative: "marketingConsulting",
        motivationCopy: {
            explain: {
                title: 'marketingExplainTitle',
                paragraph: ['marketingExplainParagraph1', 'marketingExplainParagraph2']
            }
        },

        subservicesTitle: "marketingSubservicesTitle",
        subservices: [
            {
                copy: { title: 'eventsTitle', text: 'eventsText', icon: require("../assets/img/eventsIcon.svg"), alternative: "events" },
                checkpoints: ['eventsCheckpoint1', 'eventsCheckpoint2', 'eventsCheckpoint3'],
            },
            {
                copy: { title: 'radioTVTitle', text: 'radioTVText', icon: require("../assets/img/radioTVIcon.svg"), alternative: "radioAndTV" },
                checkpoints: ['radioTVCheckpoint1', 'radioTVCheckpoint2', 'radioTVCheckpoint3'],
            },
            {
                copy: { title: 'pressTitle', text: 'pressText', icon: require("../assets/img/pressIcon.svg"), alternative: "press" },
                checkpoints: ['pressCheckpoint1', 'pressCheckpoint2', 'pressCheckpoint3'],
            },
            {
                copy: { title: 'marketResearchTitle', text: 'marketResearchText', icon: require("../assets/img/marketResearchIcon.svg"), alternative: "marketResearch" },
                checkpoints: ['marketResearchCheckpoint1', 'marketResearchCheckpoint2', 'marketResearchCheckpoint3', 'marketResearchCheckpoint4'],
            },
        ],
    },

    {
        id: "branding-and-graphic-design",
        title: "brandingTitle",
        copy: "brandingCopy",
        illustration: require("../assets/img/brandingIllustration.svg"),
        alternative: "brandingAndGraphicDesign",
        briefId: 'brandingBrief',

        motivationTitle: "brandingMotivationTitle",
        motivationCopy: {
            explain: {
                title: 'brandingExplainTitle',
                paragraph: ['brandingExplainParagraph1', 'brandingExplainParagraph2']
            }
        },

        subservicesTitle: "brandingSubservicesTitle",
        subservices: [
            {
                copy: { title: 'brandingSSTitle', text: 'brandingSSText', icon: require("../assets/img/brandingIcon.svg"), alternative: "branding" },
                checkpoints: ['brandingSSCheckpoint1', 'brandingSSCheckpoint2', 'brandingSSCheckpoint3'],
            },
            {
                copy: { title: 'printGraphicsTitle', text: 'printGraphicsText', icon: require("../assets/img/printGraphicsIcon.svg"), alternative: "printGraphics" },
                checkpoints: ['printGraphicsCheckpoint1', 'printGraphicsCheckpoint2', 'printGraphicsCheckpoint3'],
            },
            {
                copy: { title: 'digitalDesignTitle', text: 'digitalDesignText', icon: require("../assets/img/digitalDesignIcon.svg"), alternative: "digitalDesign" },
                checkpoints: ['digitalDesignCheckpoint1', 'digitalDesignCheckpoint2', 'digitalDesignCheckpoint3'],
            },
            {
                copy: { title: 'socialMediaGraphicsTitle', text: 'socialMediaGraphicsText', icon: require("../assets/img/socialMediaGraphicsIcon.svg"), alternative: "socialMediaGraphics" },
                checkpoints: ['socialMediaGraphicsCheckpoint1', 'socialMediaGraphicsCheckpoint2', 'socialMediaGraphicsCheckpoint3'],
            },
        ],

        packagesTitle: "brandingPackagesTitle",
        packages: [
            {
                title: 'brandingBasicPackageTitle',
                description: 'brandingBasicPackageDesc',
                items: ["brandingBasicPackageItem1", "brandingBasicPackageItem2", "brandingBasicPackageItem3", "brandingBasicPackageItem4", "brandingBasicPackageItem5", "brandingBasicPackageItem6"]
            },
            {
                title: 'brandingStandardPackageTitle',
                description: 'brandingStandardPackageDesc',
                items: ["brandingStandardPackageItem1", "brandingStandardPackageItem2", "brandingStandardPackageItem3", "brandingStandardPackageItem4", "brandingStandardPackageItem5", "brandingStandardPackageItem6", "brandingStandardPackageItem7", "brandingStandardPackageItem8", "brandingStandardPackageItem9", "brandingStandardPackageItem10"]
            },
            {
                title: 'brandingCustomPackageTitle',
                description: 'brandingCustomPackageDesc',
                items: ["brandingCustomPackageItem1"]
            },
        ]
    },

    {
        id: "social-media-management",
        title: "socialMediaTitle",
        copy: "socialMediaCopy",
        illustration: require("../assets/img/socialMediaIllustration.svg"),
        alternative: "socialMediaManagement",
        briefId: 'socialMediaBrief',

        motivationTitle: "socialMediaMotivationTitle",
        motivationCopy: {
            explain: {
                title: 'socialMediaExplainTitle',
                paragraph: ['socialMediaExplainParagraph1', 'socialMediaExplainParagraph2']
            }
        },

        subservicesTitle: "socialMediaSubservicesTitle",
        subservices: [
            {
                copy: { title: 'facebookTitle', text: 'facebookText', icon: require("../assets/img/facebookIcon.svg"), alternative: "facebook" },
                checkpoints: ['facebookCheckpoint1', 'facebookCheckpoint2', 'facebookCheckpoint3', 'facebookCheckpoint4'],
            },
            {
                copy: { title: 'youtubeTitle', text: 'youtubeText', icon: require("../assets/img/youtubeIcon.svg"), alternative: "youtube" },
                checkpoints: ['youtubeCheckpoint1', 'youtubeCheckpoint2', 'youtubeCheckpoint3'],
            },
            {
                copy: { title: 'instagramTitle', text: 'instagramText', icon: require("../assets/img/instagramIcon.svg"), alternative: "instagram" },
                checkpoints: ['instagramCheckpoint1', 'instagramCheckpoint2', 'instagramCheckpoint3', 'instagramCheckpoint4'],
            },
            {
                copy: { title: 'linkedInTitle', text: 'linkedInText', icon: require("../assets/img/linkedInIcon.svg"), alternative: "linkedIn" },
                checkpoints: ['linkedInCheckpoint1', 'linkedInCheckpoint2', 'linkedInCheckpoint3', 'linkedInCheckpoint4'],
            },
            {
                copy: { title: 'communityManagementTitle', text: 'communityManagementText', icon: require("../assets/img/communityManagementIcon.svg"), alternative: "communityManagement" },
                checkpoints: ['communityManagementCheckpoint1', 'communityManagementCheckpoint2', 'communityManagementCheckpoint3'],
            },
            {
                copy: { title: 'photoVideoTitle', text: 'photoVideoText', icon: require("../assets/img/photoVideoIcon.svg"), alternative: "photoVideo" },
                checkpoints: ['photoVideoCheckpoint1', 'photoVideoCheckpoint2', 'photoVideoCheckpoint3', 'photoVideoCheckpoint4'],
            },
        ],

        packagesTitle: "socialMediaPackagesTitle",
        packages: [
            {
                title: 'socialMediaBasicPackageTitle',
                description: 'socialMediaBasicPackageDesc',
                items: ["socialMediaBasicPackageItem1", "socialMediaBasicPackageItem2", "socialMediaBasicPackageItem3", "socialMediaBasicPackageItem4", "socialMediaBasicPackageItem5", "socialMediaBasicPackageItem6"]
            },
            {
                title: 'socialMediaStandardPackageTitle',
                description: 'socialMediaStandardPackageDesc',
                items: ["socialMediaStandardPackageItem1", "socialMediaStandardPackageItem2", "socialMediaStandardPackageItem3", "socialMediaStandardPackageItem4", "socialMediaStandardPackageItem5", "socialMediaStandardPackageItem6", "socialMediaStandardPackageItem7", "socialMediaStandardPackageItem8", "socialMediaStandardPackageItem9", "socialMediaStandardPackageItem10", "socialMediaStandardPackageItem11", "socialMediaStandardPackageItem12", "socialMediaStandardPackageItem13", "socialMediaStandardPackageItem14", "socialMediaStandardPackageItem15"]
            },
            {
                title: 'socialMediaCustomPackageTitle',
                description: 'socialMediaCustomPackageDesc',
                items: ["socialMediaCustomPackageItem1"]
            },
        ]
    },

    {
        id: "web-design-and-development",
        title: "devTitle",
        copy: "devCopy",
        illustration: require("../assets/img/devIllustration.svg"),
        alternative: "webDesign",
        briefId: 'webDesignBrief',

        motivationTitle: "devMotivationTitle",
        motivationCopy: {
            explain: {
                title: 'devExplainTitle',
                paragraph: ['devExplainParagraph1', 'devExplainParagraph2']
            }
        },

        subservicesTitle: "devSubservicesTitle",
        subservices: [
            {
                copy: { title: 'websiteTitle', text: 'websiteText', icon: require("../assets/img/websiteIcon.svg"), alternative: "website" },
                checkpoints: ['websiteCheckpoint1', 'websiteCheckpoint2', 'websiteCheckpoint3', 'websiteCheckpoint4'],
            },
            {
                copy: { title: 'onlineShopTitle', text: 'onlineShopText', icon: require("../assets/img/onlineShopIcon.svg"), alternative: "onlineShop" },
                checkpoints: ['onlineShopCheckpoint1', 'onlineShopCheckpoint2', 'onlineShopCheckpoint3'],
            },
        ],

        packagesTitle: "devPackagesTitle",
        packages: [
            {
                title: 'devBasicPackageTitle',
                description: 'devBasicPackageDesc',
                items: ["devBasicPackageItem1", "devBasicPackageItem2", "devBasicPackageItem3", "devBasicPackageItem4", "devBasicPackageItem5", "devBasicPackageItem6", "devBasicPackageItem7", "devBasicPackageItem8", "devBasicPackageItem9", "devBasicPackageItem10", "devBasicPackageItem11"]
            },
            {
                title: 'devStandardPackageTitle',
                description: 'devStandardPackageDesc',
                items: ["devStandardPackageItem1", "devStandardPackageItem2", "devStandardPackageItem3", "devStandardPackageItem4", "devStandardPackageItem5", "devStandardPackageItem6", "devStandardPackageItem7", "devStandardPackageItem8", "devStandardPackageItem9", "devStandardPackageItem10", "devStandardPackageItem11", "devStandardPackageItem12", "devStandardPackageItem13", "devStandardPackageItem14"]
            },
            {
                title: 'devCustomPackageTitle',
                description: 'devCustomPackageDesc',
                items: ["devCustomPackageItem1"]
            },
        ]
    },

    {
        id: "seo",
        title: "seoTitle",
        copy: "seoCopy",
        illustration: require("../assets/img/seoIllustration.svg"),
        alternative: "seo",

        motivationTitle: "seoMotivationTitle",
        motivationCopy: {
            explain: {
                title: 'seoExplainTitle',
                paragraph: ['seoExplainParagraph1', 'seoExplainParagraph2']
            }
        },

        subservicesTitle: "seoSubservicesTitle",
        subservices: [
            {
                copy: { title: 'onPageSEOTitle', text: 'onPageSEOText', icon: require("../assets/img/onPageSeoIcon.svg"), alternative: "onPageSEO" },
                checkpoints: ['onPageSEOCheckpoint1', 'onPageSEOCheckpoint2', 'onPageSEOCheckpoint3', 'onPageSEOCheckpoint4'],
            },
            {
                copy: { title: 'offPageSEOTitle', text: 'offPageSEOText', icon: require("../assets/img/offPageSeoIcon.svg"), alternative: "offPageSEO" },
                checkpoints: ['offPageSEOCheckpoint1', 'offPageSEOCheckpoint2', 'offPageSEOCheckpoint3', 'offPageSEOCheckpoint4'],
            },
        ],

        packagesTitle: "seoPackagesTitle",
        packages: [
            {
                title: 'seoBasicPackageTitle',
                description: 'seoBasicPackageDesc',
                items: ["seoBasicPackageItem1", "seoBasicPackageItem2", "seoBasicPackageItem3", "seoBasicPackageItem4", "seoBasicPackageItem5"]
            },
            {
                title: 'seoStandardPackageTitle',
                description: 'seoStandardPackageDesc',
                items: ["seoStandardPackageItem1", "seoStandardPackageItem2", "seoStandardPackageItem3", "seoStandardPackageItem4", "seoStandardPackageItem5", "seoStandardPackageItem6", "seoStandardPackageItem7", "seoStandardPackageItem8", "seoStandardPackageItem9", "seoStandardPackageItem10"]
            },
            {
                title: 'seoCustomPackageTitle',
                description: 'seoCustomPackageDesc',
                items: ["seoCustomPackageItem1"]
            },
        ]
    },

    {
        id: "ads",
        title: "adsTitle",
        copy: "adsCopy",
        illustration: require("../assets/img/adsIllustration.svg"),
        alternative: "ads",
        briefId: 'adsBrief',
        
        motivationTitle: "adsMotivationTitle",
        motivationCopy: {
            explain: {
                title: 'adsExplainTitle',
                paragraph: ['adsExplainParagraph1', 'adsExplainParagraph2']
            }
        },

        subservicesTitle: "adsSubservicesTitle",
        subservices: [
            {
                copy: { title: 'facebookInstagramAdsTitle', text: 'facebookInstagramAdsText', icon: require("../assets/img/facebookAdsIcon.svg"), alternative: "facebookAds" },
                checkpoints: ['facebookInstagramAdsCheckpoint1', 'facebookInstagramAdsCheckpoint2', 'facebookInstagramAdsCheckpoint3', 'facebookInstagramAdsCheckpoint4'],
            },
            {
                copy: { title: 'googleAdsTitle', text: 'googleAdsText', icon: require("../assets/img/googleAdsIcon.svg"), alternative: "googleAds" },
                checkpoints: ['googleAdsCheckpoint1', 'googleAdsCheckpoint2', 'googleAdsCheckpoint3', 'googleAdsCheckpoint4'],
            },
            {
                copy: { title: 'youtubeAdsTitle', text: 'youtubeAdsText', icon: require("../assets/img/youtubeAdsIcon.svg"), alternative: "youtubeAds" },
                checkpoints: ['youtubeAdsCheckpoint1', 'youtubeAdsCheckpoint2', 'youtubeAdsCheckpoint3'],
            },
            {
                copy: { title: 'wazeAdsTitle', text: 'wazeAdsText', icon: require("../assets/img/wazeAdsIcon.svg"), alternative: "wazeAds" },
                checkpoints: ['wazeAdsCheckpoint1', 'wazeAdsCheckpoint2', 'wazeAdsCheckpoint3'],
            },
        ],
    },

    {
        id: "software-development",
        title: "softwareTitle",
        copy: "softwareCopy",
        illustration: require("../assets/img/softwareIllustration.svg"),
        alternative: "softwareDevelopment",

        motivationTitle: "softwareMotivationTitle",
        motivationCopy: {
            explain: {
                title: 'softwareExplainTitle',
                paragraph: ['softwareExplainParagraph1', 'softwareExplainParagraph2']
            }
        },

        subservicesTitle: "softwareSubservicesTitle",
        subservices: [
            {
                copy: { title: 'webMobileDevTitle', text: 'webMobileDevText', icon: require("../assets/img/webMobileAppIcon.svg"), alternative: "webMobileApp" },
                checkpoints: ['webMobileDevCheckpoint1', 'webMobileDevCheckpoint2', 'webMobileDevCheckpoint3', 'webMobileDevCheckpoint4'],
            },
            {
                copy: { title: 'enterpiseDevTitle', text: 'enterpiseDevText', icon: require("../assets/img/enterpriseAppIcon.svg"), alternative: "enterpriseApp" },
                checkpoints: ['enterpiseDevCheckpoint1', 'enterpiseDevCheckpoint2', 'enterpiseDevCheckpoint3'],
            },
            {
                copy: { title: 'softwareConsultancyTitle', text: 'softwareConsultancyText', icon: require("../assets/img/softwareConsultancyIcon.svg"), alternative: "softwareConsultancy" },
                checkpoints: ['softwareConsultancyCheckpoint1', 'softwareConsultancyCheckpoint2', 'softwareConsultancyCheckpoint3'],
            },
            {
                copy: { title: 'ideaTitle', text: 'ideaText', icon: require("../assets/img/ideaIcon.svg"), alternative: "yourIdea" },
                checkpoints: ['ideaCheckpoint1', 'ideaCheckpoint2'],
            },
        ],
    },
];
